
html, body {
  height: 100%;
}

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
  background-color: #ECECEA;
}

.bg-image {
  width: 100%;
  height: 100%;
  background: url('./images/chess.png') transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(3px);
}

.cover-container {
  max-width: 65em;
}

.cover {
  padding: 0 1.5rem;
}

.home-container {
  height: 100%;
  text-align: center;
  padding-top: 5rem;
  width: 100%;
  background: url('./images/chess.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

header {
  margin-bottom: 2rem;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: transparent;
  border: 1px solid transparent;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 48px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 48px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.game-board {
  min-width: 376px;
}

.icons-attribution, .game-status, .fallen-soldier-block{
  margin-top: 20px;
  min-height: 20px;
}



/*Board color scheme Wheat from http://omgchess.blogspot.com/2015/09/chess-board-color-schemes.html*/
.dark-square{
background-color: RGB(187,190,100);
}

.light-square{
    background-color: RGB(234,240,206);
}

#player-turn-box{
  width: 32px;
  height: 32px;
  border: 1px solid #000;
  margin-bottom: 10px;
}

h3{
  margin-bottom: 5px;
}

.video-container {
  position: relative;
  float: left;
}

.video-container img {
  right: 4px;
  position: absolute;
}

video {
  width: 200px;
  height: 150px;
  margin-right: 10px;
  outline: 4px solid transparent;
}

video.selected {
  outline-color: deepskyblue;
  animation: outlinepulse 1.5s ease-in infinite;
}

@keyframes outlinepulse {
  0% {
    outline-width: 2px;
  }
  70% {
    outline-width: 7px;
    outline-color: transparent;
  }

  100% {
    outline-width: 4px;
    outline-color: transparent;
  }
}

.video-container .connection-status {
  position: absolute;
  left: 10px;
  top: 10px;
}

.connection-status.connected {
  transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
	}

  70% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}
